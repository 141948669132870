<template>
  <div id="say-yes">
    <h1>{{ question }}</h1>
    <div class="container-img">
      <div class="didascalia">
        Mi dispiace, ho sbagliato e ho fatto le cose male.<br>
        Ho ignorato pensieri che avrei dovuto condividere con te.<br>
        Entrambi non siamo perfetti,<br>
        ma finalmente ho capito che tu sei il mio posto sicuro,<br>
        lo sei sempre stata.<br>
        Ho fatto un casino con quello che avevo dentro<br>
        ed è per questo che non voglio tenermi più nulla.<br>
        E desidero imparare a comunicare, perdonare e crescere insieme a te. <br>
        Il mio carattere può sembrare complicato<br>
        ma il mio cuore sente intensamente qualsiasi cosa<br>
        e desidera amare.<br>
        Abbiamo avuto dei problemi di comunicazione<br>
        non abbiamo parlato adeguatamente e tutto è poi crollato<br>
        ma possiamo risolvere, migliorare e andare avanti.<br>
        Lo so che ora la situazione non è delle migliori<br>
        ma mi importa troppo di te e voglio trovare un modo<br>
        per far funzionare noi, piuttosto che buttare via tutto.<br>
        Non voglio lasciarti andare.<br>
        Ti amo.
      </div>
      <video v-if="showVideo" ref="videoPlayer" autoplay @loadedmetadata="getVideoDuration" @ended="handleVideoEnd">
        <source src="@/assets/gif/video.mp4" type="video/mp4" />
      </video>
      <img v-if="!showVideo" :src="images[currentImageIndex]" />
      <!-- Audio che si riproduce automaticamente -->
      <audio ref="audioPlayer" autoplay loop>
        <source src="@/assets/audio/utopia.mp3" type="audio/mpeg" />
      </audio>
    </div>
    <div class="contaner-buttons">
      <el-button @mouseenter="saidNo()" :style="isHover ? 'position: absolute' : ''" @click="saidNo()" class="no-button"
        type="danger">NO</el-button>
      <el-button class="yes-button" @click="saidYes()" type="primary">SI</el-button>
    </div>
  </div>

</template>

<script>
import firebase from "firebase"

export default {
  name: 'SayYes',
  components: {
  },
  props: {
  },
  data() {
    return {
      b: null,
      isHover: false,
      isAnswered: false,
      showVideo: true,
      currentImageIndex: 0,
      images: [
        require('@/assets/gif/0.jpg'),
        require('@/assets/gif/1.jpg'),
        require('@/assets/gif/2.jpg'),
        require('@/assets/gif/3.jpg'),
        require('@/assets/gif/4.jpg'),
        require('@/assets/gif/5.jpg'),
        require('@/assets/gif/6.jpg'),
        require('@/assets/gif/7.jpg'),
        require('@/assets/gif/8.jpg'),
        require('@/assets/gif/9.jpg'),
      ]
    }
  },
  mounted() {
    if (!this.isLogged) {
      this.$router.push({ name: "secretWord" })
    }
    this.b = document.querySelector("button");

    const audioPlayer = this.$refs.audioPlayer;

    // Muta l'audio
    audioPlayer.volume = 0;

    //this.muteAudioForSeconds(this.getVideoDuration()); // Muta per 5 secondi
  },
  computed: {
    ip() {
      return this.$store.state.ip
    },
    isLogged() {
      return this.$store.state.isLogged
    },
    question() {
      return process.env.VUE_APP_PROJECT_QUESTION
    }
  },
  methods: {
    changeImage() {
      // Aggiorna l'indice dell'immagine
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    },
    handleVideoEnd() {
      this.showVideo = false; // Nasconde il video quando è finito
      setInterval(this.changeImage, 3000);
      const audioPlayer = this.$refs.audioPlayer;
      audioPlayer.volume = 1; // Ripristina il volume
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    saidYes() {
      if (!this.isAnswered) {
        var collection = firebase.firestore().collection(process.env.VUE_APP_FIREBASE_PROJECT_TITLE)
        var date = new Date()
        var fullYearDate = date.getFullYear() + "-" + date.getUTCMonth() + "-" + date.getDate()
        collection.doc("entered").collection("/" + fullYearDate).add({
          ip: this.ip,
          date: date,
          orderDate: fullYearDate,
          isMobile: this.isMobile(),
          response: true,
          userAgent: navigator.userAgent
        })

        this.isAnswered = true
      }
      this.$message({
        showClose: true,
        message: 'Chiamami...',
        type: 'success'
      })
    },
    saidNo() {
      this.isHover = true
      var i = Math.floor(Math.random() * 500) + 1;
      var j = Math.floor(Math.random() * 500) + 1;
      this.b.style.left = i + "px";
      this.b.style.top = j + "px";
    }
  }
}
</script>

<style lang="less">
#say-yes {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .container-img {
    position: relative;
    display: flex;
    flex-direction: row;
    /* Layout orizzontale per desktop */

    .didascalia {
      margin-right: 40px;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }

  img {
    width: 100%;
    max-width: 500px;
    margin-bottom: 1rem;
  }

  video {
    width: 100%;
    max-width: 500px;
    margin-bottom: 1rem;
  }

  .contaner-buttons {
    display: flex;

    .no-button {
      display: block;
    }

    .yes-button {
      margin-left: 30px;
    }
  }

  /* Media query per dispositivi mobili */
  @media (max-width: 768px) {
    .container-img {
      flex-direction: column;
      /* Cambia il layout a verticale per dispositivi mobili */
      align-items: center;

      .didascalia {
        margin: 0 0 10px 0;
        /* Rimuove il margine a destra e aggiunge un margine in basso */
        text-align: center;
        /* Centra il testo per una migliore leggibilità su dispositivi mobili */
        margin-bottom: 40px;
      }
    }
  }
}
</style>