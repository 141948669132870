<template>
  <div id="secret-word" @keypress.enter="checkWord()">
    <div class="container-enter">
      <div class="container-input">
        <el-input class="input-secret-word" v-model="secretWord" />
        <el-button @click="checkWord()" class="button-enter" type="primary">ENTRA</el-button>
      </div>
    </div>
  </div>
</template>
  
<script>
import firebase from "firebase"
export default {
  name: 'SecretWord',
  components: {
  },
  data() {
    return {
      secretWord: "",
      ip: ""
    }
  },
  mounted() {
    this.$store.commit('setIsLogged', false)
    this.getIp()
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    getIp() {
      fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
          this.ip = ip;
          this.$store.commit('setIp', ip)
        });
    },
    checkWord() {
      if (this.secretWord.toUpperCase() === process.env.VUE_APP_PROJECT_SECRET_WORD) {
        var coll = firebase.firestore().collection(process.env.VUE_APP_FIREBASE_PROJECT_TITLE)
          var d = new Date()
          var fd = d.getFullYear() + "-" + d.getUTCMonth() + "-" + d.getDate()
          coll.doc("access").collection("/" + fd).add({
            ip: this.ip,
            date: d,
            orderDate: fd,  
            isMobile: this.isMobile(),
            userAgent: navigator.userAgent
          })
        this.$store.commit('setIsLogged', true)
        this.$router.push({ name: 'sayYes' })
      } else {
        if (this.secretWord.length > 0) {
          this.$store.commit('setIsLogged', false)
          var collection = firebase.firestore().collection(process.env.VUE_APP_FIREBASE_PROJECT_TITLE)
          var date = new Date()
          var fullYearDate = date.getFullYear() + "-" + date.getUTCMonth() + "-" + date.getDate()
          collection.doc("notEntered").collection("/" + fullYearDate).add({
            ip: this.ip,
            date: date,
            orderDate: fullYearDate,  
            isMobile: this.isMobile(),
            userAgent: navigator.userAgent
          })
          this.$message({
            showClose: true,
            message: 'Sbagliato...',
            type: 'error'
          })
        }
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged
    }
  }
}
</script>
  
<style lang="less">
#secret-word {
  .container-enter {
    display: flex;
    justify-content: center;
    margin-top: 15vh;

    .container-input {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .input-secret-word {
        width: 300px;
      }

      .button-enter {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-self: center;
        margin-top: 10px;
      }
    }
  }
}
</style>
  