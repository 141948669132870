import { createStore } from 'vuex'

export default createStore({
  state: {
    isLogged: false,
    ip: ""
  },
  getters: {
    getIsLogged(state) {
      return state.isLogged;
    },
    getIp(state) {
      return state.ip;
    }
  },
  mutations: {
    setIsLogged(state, isLogged) {
      state.isLogged = isLogged;
    },
    setIp(state, ip) {
      state.ip = ip;
    }
  },
  actions: {
    setIsLogged(context, isLogged) {
      context.state.isLogged = isLogged;
    },
    setIp(context, ip) {
      context.state.ip = ip;
    }
  },
  modules: {
  }
})
